import styled from "@emotion/styled"
import { Link } from "gatsby"
import React, { useContext, useMemo } from "react"
import SaleBadge from "../../assets/icons/sale-badge"
import StoreContext from "../../context/store-context"
import { formatVariantPrice, salePercentage } from "../../util/prices"
import Image from "../base/image"
import VariantPrice from "./price"

export const ProductContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: ${({ theme }) => theme.gap.betweenProducts[0]}px;

  ${({ theme }) => theme.bp.desktop} {
    grid-template-columns: repeat(4, 1fr);
  }

  ${({ theme }) => theme.bp.hd} {
    grid-template-columns: repeat(4, 1fr);
  }

  padding: 0px ${({ theme }) => theme.gap.betweenProducts[0]}px;

  ${({ theme }) => theme.bp.desktop} {
    gap: ${({ theme }) => theme.gap.betweenProducts[1]}px;
    padding: 0px ${({ theme }) => theme.gap.betweenProducts[1]}px;
  }
`

export const Product = styled(Link)`
  text-decoration: none;
  color: ${({theme}) => theme.colors.darkGreen};
`

const ProductImage = styled.div`
  flex-grow: 1;
  position: relative;
`

const ProductContent = styled.div`
  padding: 10px 20px;
  letter-spacing: .06em;

  ${({ theme }) => theme.bp.desktop} {
    padding: 18px 28px;
  }
`

const ProductName = styled.h3`
  margin-bottom: 1px;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;
  line-height: 1.5;
  font-weight: normal;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
`
/*
const ProductColor = styled.p`
  margin-bottom: 5px;
  font-size: ${({ theme }) => theme.fontSizes[1]}px;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[2]}px;
  }
`
*/

const ProductPrice = styled.p`
  margin-bottom: 0;
  font-size: ${({ theme }) => theme.fontSizes[0]}px;

  ${({ theme }) => theme.bp.desktop} {
    font-size: ${({ theme }) => theme.fontSizes[1]}px;
  }
`

const ProductHighlight = ({ product, slugs, isSoldOut, productVariants }) => {
  const { id, primaryImage, featuredImage, title } = product
  const { categorySlug, productSlug, fullSlug } = slugs
  const { cart } = useContext(StoreContext)

  const shouldShowBadge = useMemo(() => {
    if (productVariants?.length) {
      const salePrice = productVariants.find(
        (v) => v?.calculated_price < v?.original_price
      )
      if (salePrice) {
        return { variant: salePrice, sale: true }
      }
      return { variant: productVariants[0], sale: false }
    }
  }, [productVariants])

  const giftCardPrice = () => {
    console.log(product.variants)
    const sortedVariants = product.variants.sort((a, b) => {
      return Number(a.title) - Number(b.title)
    })

    return sortedVariants[0]
  }

  return (
    <Product
      key={id}
      to={fullSlug ? fullSlug : `/${categorySlug}/${productSlug}`}
    >
      <ProductImage>
        {shouldShowBadge?.sale && (
          <SaleBadge
            small={true}
            text={salePercentage(shouldShowBadge.variant)}
          />
        )}
        <Image
          primary={primaryImage && primaryImage}
          secondary={featuredImage && featuredImage}
          ratio={4 / 6}
        />
      </ProductImage>
      <ProductContent>
        <ProductName>
          {title}
        </ProductName>
        {/*<ProductColor>
          {color?.name}
        </ProductColor>*/}
        <ProductPrice>
          {isSoldOut ? (
            "Sold Out"
          ) : product.handle === "gift-card" ? (
            `From: ${formatVariantPrice(cart, giftCardPrice())}`
          ) : (
            <VariantPrice cart={cart} variant={shouldShowBadge?.variant} />
          )}
        </ProductPrice>
      </ProductContent>
    </Product>
  )
}

export default ProductHighlight
